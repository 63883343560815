<!--门禁模块--通行模式页--认证终端编辑页-->
<template>
  <div id="terminaleditor" v-loading="loading" :element-loading-text="`${$t('trafficpatterns.a1')}....`" ref="terminaleditor">
    <div class="header">
      <my-headertitle :quit="true">
        {{ $t("trafficpatterns.a8") }}
        <template v-slot:name>
          <a-icon type="pushpin" :style="{ 'margin-right': '5px', color: '#7682CE' }"/>
          <span style="margin-right: 70px">{{ data.accessRoleName }}</span>
        </template>
      </my-headertitle>
    </div>
    <div class="inquire">
      <div class="inquire-container">
        <a-input v-model="form.name" :placeholder="$t('trafficpatterns.a12')" style="width:250px;margin-right:15px"/>
        <a-button type="primary" style="width: 120px" @click="getAlldata">{{ $t("trafficpatterns.a13") }}</a-button>
      </div>
    </div>
    <div class="main">
      <div class="main-all-vessel">
        <my-tabletitle>{{ $t("trafficpatterns.a14") }}</my-tabletitle>
        <a-table :row-selection="allrowSelection" :columns="columns" :dataSource="alterallList" :scroll="{ x: 1300 }" :pagination="pagination" :rowKey="(record) => record.id">
        </a-table>
      </div>
      <div class="main-button-vessel">
        <a-button type="primary" style="width: 120px" @click="add">↓ {{ $t("trafficpatterns.a15") }}</a-button>
        <a-button type="primary" style="width: 120px" @click="Delete">↑ {{ $t("trafficpatterns.a16") }}</a-button>
      </div>
      <div class="main-register-vessel">
        <my-tabletitle>{{ $t("trafficpatterns.a5") }}</my-tabletitle>
        <a-table :row-selection="rowSelection" :columns="columns" :dataSource="registerList" :scroll="{ x: 1300 }" :pagination="pagination" :rowKey="(record) => record.id">
        </a-table>
      </div>
    </div>
    <!-- 底部功能栏 -->
    <div class="footer">
      <a-button type="primary" @click="openCalendarTime">{{$t("trafficpatterns.a17")}}</a-button>
      <a-button type="primary" style="width: 120px" @click="update" :loading="buttonloading">{{ $t("trafficpatterns.a18") }}</a-button>
    </div>

    <!-- 时间表·日历选择 -->
    <a-modal :title="$t('trafficpatterns.a19')" :width="400" centered :visible="visible" :maskClosable="false" :getContainer="() => this.$refs.terminaleditor" @ok="allHandleOk" @cancel="allHandleCancel">
      <div>
        <div class="row">
          <div>
            <p>{{ $t("trafficpatterns.a20") }}</p>
            <a-select :placeholder="$t('trafficpatterns.a21')" v-model="timeTableVlaue" style="width: 150px">
              <a-select-option v-for="item in timeTableList" :key="item.key">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </div>
          <div>
            <p>{{ $t("trafficpatterns.a22") }}</p>
            <a-select :placeholder="$t('trafficpatterns.a23')" v-model="calendarValue" style="width: 150px">
              <a-select-option v-for="item in calendarList" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div>
          <span class="margin">{{ $t("trafficpatterns.a24") }}</span>
          <a-radio-group name="radioGroup" v-model="radioValue">
            <a-radio :value="1">{{ $t("trafficpatterns.a25") }}</a-radio>
            <a-radio :value="0">{{ $t("trafficpatterns.a26") }}</a-radio>
          </a-radio-group>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import {
  getTPData,
  doorTimetableListKT,
  getCalendar,
  getAllTerminal,
  UpdateregisterList,
} from "../../api/door";
import { tableSort } from "../../utils/utils";

export default {
  name: "terminaleditor",
  data() {
    return {
      form: {
        name: "",
      },
      visible: false,
      loading: false,
      buttonloading: false,
      registerList: [],
      allList: [],
      alterallList: [],
      data: {},
      timeTableList: undefined,
      calendarList: undefined,
      radioValue: 0,
      addList: [],
      deleteList: [],
      timeTableVlaue: undefined,
      calendarValue: undefined,
      scheduleName: undefined,
      scheduleId: undefined,
      calendarName: undefined,
      calendarId: undefined,
      pagination: {
        total: 0, //数据总数
        pageSize: 5, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => ` ${total} ${this.$t("trafficpatterns.a27")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          align: "center",
          width: 70,
          fixed: "left"
        },
        {
          title: this.$t("trafficpatterns.a28"),
          dataIndex: "customerName",
          sorter: (a, b) => {
            return tableSort(a, b, "customerName");
          },
          width: 150
        },
        {
          title: this.$t("trafficpatterns.a29"),
          dataIndex: "siteName",
          sorter: (a, b) => {
            return tableSort(a, b, "siteName");
          },
          width: 150
        },
        {
          title: this.$t("trafficpatterns.a30"),
          dataIndex: "tenantName",
          sorter: (a, b) => {
            return tableSort(a, b, "tenantName");
          },
          width: 130
        },
        {
          title: this.$t("trafficpatterns.a31"),
          dataIndex: "name",
          sorter: (a, b) => {
            return tableSort(a, b, "name");
          },
          width: 130
        },
        {
          title: this.$t("trafficpatterns.a32"),
          dataIndex: "credentialReaderName",
          sorter: (a, b) => {
            return tableSort(a, b, "credentialReaderName");
          }
        },
        {
          title: this.$t("trafficpatterns.a33"),
          dataIndex: "EQUIPMENT_NAME2",
          sorter: (a, b) => {
            return tableSort(a, b, "EQUIPMENT_NAME2");
          },
          width: 100
        },
        {
          title: this.$t("trafficpatterns.a34"),
          dataIndex: "alertSet",
          customRender: (text, record, index) =>
            record.alertSet == 0
              ? this.$t("trafficpatterns.a35")
              : this.$t("trafficpatterns.a36"),
          sorter: (a, b) => {
            return tableSort(a, b, "alertSet");
          },
          width: 100,
          fixed: "right",
        },
        {
          title: this.$t("trafficpatterns.a37"),
          dataIndex: "scheduleName",
          sorter: (a, b) => {
            return tableSort(a, b, "scheduleName");
          },
          width: 130,
          fixed: "right",
        },
        {
          title: this.$t("trafficpatterns.a38"),
          dataIndex: "calendarName",
          sorter: (a, b) => {
            return tableSort(a, b, "calendarName");
          },
          width: 100,
          fixed: "right",
        },
      ],
    };
  },
  watch: {
    // 监听全部认证终端数据，对数据进行加工
    allList: {
      handler(newName, oldName) {
        let registerList = this.registerList;
        let allList = JSON.stringify(this.allList);
        allList = JSON.parse(allList);
        for (let i = 0; i < registerList.length; i++) {
          allList = allList.filter((item) => {
            return item.id !== registerList[i].id;
          });
        }
        this.alterallList = allList;
      },
      deep: true,
    },
  },
  async mounted() {
    this.loading = true;
    let data = {
      siteId: this.$route.query.id,
      accessRoleId: this.$route.query.accessRoleId,
    };
    await getTPData(data)
      .then((res) => {
        let { data } = res;
        console.log(data);
        this.data = data;
        this.registerList = data.arDeviceList;
      })
      .catch((err) => {
        console.log(err);
      });
    let datas = {};
    if (this.form.name == "") {
      datas = {
        siteId: this.$route.query.id,
        searchKeyword: "",
      };
    } else {
      datas = {
        siteId: this.$route.query.id,
        searchKeyword: `${this.$t('trafficpatterns.a12')},${this.form.name}`,
      };
    }
    await getAllTerminal(datas)
      .then((res) => {
        let { data } = res;
        console.log(data);
        this.allList = data;
      })
      .catch((err) => {
        console.log(err);
      });
    this.loading = false;
    this.getcalendarTime();
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.deleteList = selectedRows;
        },
      };
    },
    allrowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.addList = selectedRows;
        },
      };
    },
  },
  methods: {
    // 获取获取当前全部的认证终端数据
    getAlldata() {
      let data = {};
      if (this.form.name == "") {
        data = {
          siteId: this.$route.query.id,
          searchKeyword: "",
        };
      } else {
        data = {
          siteId: this.$route.query.id,
          searchKeyword: `${this.$t('trafficpatterns.a12')},${this.form.name}`,
        };
      }
      getAllTerminal(data)
        .then((res) => {
          let { data } = res;
          console.log("data", data);
          this.allList = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取通行时间表和日历列表
    getcalendarTime() {
      let data = {
        clientId: this.$store.getters.clientId,
        siteId: this.$route.query.id,
        schType: 3,
      };
      doorTimetableListKT(data)
        .then((res) => {
          this.timeTableList = res.data.children[0].children;
          // console.log(res.data.children[0].children);
        })
        .catch((err) => {
          console.log(err);
        });
      let calendardata = {
        siteId: this.$route.query.id,
      };
      getCalendar(calendardata)
        .then((res) => {
          let { data } = res;
          this.calendarList = data;
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 开启时间表·日历修改对话框
    openCalendarTime() {
      if (this.deleteList.length == 0) {
        this.$error({
          title: this.$t("trafficpatterns.a39"),
          content: this.$t("trafficpatterns.a40"),
          centered: true,
        });
      } else {
        this.visible = true;
        this.timeTableVlaue = this.timeTableList[0].key;
        this.calendarValue = this.calendarList[0].value;
      }
    },
    // 时间表·日历修改对话框的确定按钮
    allHandleOk() {
      let scheduleId = this.timeTableVlaue;
      let calendarId = this.calendarValue;
      let scheduleName = undefined;
      let calendarName = undefined;
      let alertSet = this.radioValue;
      for (let i = 0; i < this.timeTableList.length; i++) {
        if (this.timeTableVlaue == this.timeTableList[i].key) {
          scheduleName = this.timeTableList[i].title;
        }
      }
      for (let i = 0; i < this.calendarList.length; i++) {
        if (calendarId == this.calendarList[i].value) {
          calendarName = this.calendarList[i].name;
        }
      }
      for (let i = 0; i < this.deleteList.length; i++) {
        this.deleteList[i].calendarId = calendarId;
        this.deleteList[i].calendarName = calendarName;
        this.deleteList[i].scheduleId = scheduleId.split(",")[1];
        this.deleteList[i].scheduleName = scheduleName;
        this.deleteList[i].alertSet = alertSet;
      }
      this.visible = false;
    },
    // 时间表·日历修改对话框的取消按钮
    allHandleCancel() {
      this.visible = false;
    },
    // 添加终端
    add() {
      if (this.addList.length > 0) {
        for (let i = 0; i < this.addList.length; i++) {
          this.alterallList = this.alterallList.filter((item) => {
            return item.id !== this.addList[i].id;
          });
        }
        this.registerList = this.registerList.concat(this.addList);
        this.addList = [];
      } else {
        this.$error({
          title: this.$t("trafficpatterns.a39"),
          content: this.$t("trafficpatterns.a40"),
          centered: true,
        });
      }
    },
    // 删除终端
    Delete() {
      if (this.deleteList.length > 0) {
        for (let i = 0; i < this.deleteList.length; i++) {
          this.registerList = this.registerList.filter(
            (item) => item.id !== this.deleteList[i].id
          );
        }
        this.alterallList = this.alterallList.concat(this.deleteList);
        this.deleteList = [];
      } else {
        this.$error({
          title: this.$t("trafficpatterns.a39"),
          content: this.$t("trafficpatterns.a40"),
          centered: true,
        });
      }
    },
    update() {
      let state = true;
      for (let i = 0; i < this.registerList.length; i++) {
        let calendarId = this.registerList[i].calendarId;
        let scheduleId = this.registerList[i].scheduleId;
        if (calendarId == null || scheduleId == null) {
          state = false;
        }
      }
      if (state) {
        this.buttonloading = true;
        let accessRoleDetail = this.data;
        accessRoleDetail.arDeviceList = this.registerList;
        console.log(accessRoleDetail);
        UpdateregisterList(accessRoleDetail)
          .then((res) => {
            this.buttonloading = false;
            console.log(res);
            if (res.errorCode == "00") {
              this.$message.success(res.msg);
            } else if (res.errorCode == "02") {
                this.$error({
                    title: res.msg,
                    content: res.data,
                    centered: true,
                });
              // this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.buttonloading = true;
            console.log(err);
          });
      } else {
        this.$error({
          title: this.$t("trafficpatterns.a41"),
          content: this.$t("trafficpatterns.a42"),
          centered: true,
        });
      }
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#terminaleditor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 3px;
  overflow: auto;
}
.header {
  padding: 0px 20px 0px 20px;
}
.inquire {
  min-width: 1250px;
  padding: 0px 20px;
  min-width: 850px;
}
.inquire-container{
  padding: 15px 0px;
}
.main {
  width: 100%;
  height: calc(100% - 174px);
  padding: 0px 20px;
  overflow: auto;
}
.main-all-vessel {
  overflow: auto;
}
.main-button-vessel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  overflow: auto;
}
.main-button-vessel button {
  margin: 0px 10px;
}
.main-register-vessel {
  margin-bottom: 20px;
  overflow: hidden;
}
.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  overflow: hidden;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
}
.footer button {
  margin-left: 10px;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.margin {
  margin-right: 10px;
}
p {
  margin-bottom: 10px;
}
</style>